import React, { createContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      const fetchSession = async () => {
        const { data } = await supabase.auth.getSession();
        console.log("AuthContext: Session data:", data);
        if (data?.session) {
          setUser(data.session.user);
        }
      };
  
      fetchSession();
  
      const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
        console.log("AuthContext: Auth state changed:", _event, session);
        setUser(session?.user ?? null);
      });
  
      return () => subscription.unsubscribe();
    }, []);
  
    return (
      <AuthContext.Provider value={{ user, setUser }}>
        {children}
      </AuthContext.Provider>
    );
  };
  