import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ResponseHistory = ({ responses, onEdit, onDelete, onSaveEdit }) => {
  const [editMode, setEditMode] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);
  const [currentEditText, setCurrentEditText] = useState('');

  const handleEditResponse = (responseId, responseText) => {
    setEditMode(true);
    setCurrentEditId(responseId);
    setCurrentEditText(responseText);
  };

  const handleSaveEdit = () => {
    onSaveEdit(currentEditId, currentEditText);
    setEditMode(false);
    setCurrentEditId(null);
    setCurrentEditText('');
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setCurrentEditId(null);
    setCurrentEditText('');
  };

  const stripHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const truncateText = (text, wordLimit) => {
    const strippedText = stripHtml(text);
    const words = strippedText.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return strippedText;
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6" gutterBottom>
        GPT Responses
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>Response</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responses.map((response) => (
              <React.Fragment key={response.id}>
                <TableRow>
                  <TableCell>{new Date(response.created_at).toLocaleString()}</TableCell>
                  <TableCell>
                    {editMode && currentEditId === response.id ? (
                      <ReactQuill
                        value={currentEditText}
                        onChange={setCurrentEditText}
                        style={{ height: '200px', marginBottom: '20px' }}
                      />
                    ) : (
                      <Typography>{truncateText(response.response_text, 10)}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode && currentEditId === response.id ? (
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button onClick={handleSaveEdit} variant="contained" color="primary">
                          Save Edit
                        </Button>
                        <Button onClick={handleCancelEdit} variant="outlined" color="secondary">
                          Cancel Edit
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton onClick={() => handleEditResponse(response.id, response.response_text)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => onDelete(response.id)}>
                          <Delete />
                        </IconButton>
                        <IconButton>
                          <Visibility />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ResponseHistory;
