import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://wrfccxnnpjpguxnwoimz.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndyZmNjeG5ucGpwZ3V4bndvaW16Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ3ODU0MTMsImV4cCI6MjA0MDM2MTQxM30.hznUx8sn9VAE_VOYC2-dR-yNe_2kb8PP8m__IYAfKok';


export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});