import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';

const AdminPage = () => {
  const [apiKey, setApiKey] = useState('');
  const [gptModels, setGptModels] = useState([]);
  const [activeModel, setActiveModel] = useState('');
  const [isRegistrationEnabled, setIsRegistrationEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);

        let { data: apiKeyData, error: apiKeyError } = await supabase
          .from('settings')
          .select('value')
          .eq('key', 'apiKey')
          .single();

        if (apiKeyError && apiKeyError.code !== 'PGRST116') throw apiKeyError;
        if (apiKeyData) setApiKey(apiKeyData.value);

        let { data: modelData, error: modelError } = await supabase
          .from('settings')
          .select('value')
          .eq('key', 'active_gpt_model')
          .single();

        if (modelError && modelError.code !== 'PGRST116') throw modelError;
        if (modelData) setActiveModel(modelData.value);

        let { data: modelsData, error: modelsError } = await supabase
          .from('settings')
          .select('value')
          .eq('key', 'gpt_models')
          .single();

        if (modelsError && modelsError.code !== 'PGRST116') throw modelsError;
        if (modelsData) setGptModels(JSON.parse(modelsData.value));

        let { data: registrationData, error: registrationError } = await supabase
          .from('settings')
          .select('value')
          .eq('key', 'registration_enabled')
          .single();

        if (registrationError) throw registrationError;
        setIsRegistrationEnabled(registrationData.value === 'true');
        
      } catch (error) {
        console.error('Error fetching settings:', error.message);
        setError('Fehler beim Laden der Einstellungen');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const showAlert = (message, severity = 'success') => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleSaveApiKey = async () => {
    try {
      const { error: apiKeyError } = await supabase
        .from('settings')
        .upsert([{ key: 'apiKey', value: apiKey }], { onConflict: 'key' });

      if (apiKeyError) throw apiKeyError;

      showAlert('API Key saved successfully!', 'success');
    } catch (error) {
      console.error('Error saving API Key:', error.message);
      showAlert('Error saving API Key: ' + error.message, 'error');
    }
  };

  const handleSaveGptModel = async () => {
    try {
      const { error: modelError } = await supabase
        .from('settings')
        .upsert([{ key: 'active_gpt_model', value: activeModel }], { onConflict: 'key' });

      if (modelError) throw modelError;

      showAlert('GPT Model saved successfully!', 'success');
    } catch (error) {
      console.error('Error saving GPT Model:', error.message);
      showAlert('Error saving GPT Model: ' + error.message, 'error');
    }
  };

  const handleRegistrationToggle = async () => {
    try {
      setLoading(true);
      setError('');
  
      const newStatus = !isRegistrationEnabled;
  
      const { error: updateError } = await supabase
        .from('settings')
        .update({ value: newStatus.toString() })
        .eq('key', 'registration_enabled');
  
      if (updateError) {
        const { error: insertError } = await supabase
          .from('settings')
          .insert({ key: 'registration_enabled', value: newStatus.toString() });
  
        if (insertError) throw insertError;
      }
  
      setIsRegistrationEnabled(newStatus);
      showAlert('Registration status updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating registration status:', error.message);
      showAlert('Error updating registration status: ' + error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
        Admin Settings
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 4 }}>{error}</Alert>}

      <Box sx={{ padding: 4, mb: 4, backgroundColor: '#f9f9f9', borderRadius: 1, border: '1px solid #ccc' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          OpenAI API Key
        </Typography>
        <TextField
          label="API Key"
          fullWidth
          variant="outlined"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          sx={{ backgroundColor: '#fff', mt: 2, borderColor: '#ccc' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveApiKey}
          sx={{ mt: 3, fontWeight: 'bold', backgroundColor: '#0066cc', '&:hover': { backgroundColor: '#004080' } }}
        >
          Save API Key
        </Button>
      </Box>

      <Box sx={{ padding: 4, mb: 4, backgroundColor: '#f9f9f9', borderRadius: 1, border: '1px solid #ccc' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          Active GPT Model
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#fff', mt: 2, borderColor: '#ccc' }}>
          <InputLabel>Select GPT Model</InputLabel>
          <Select
            value={activeModel}
            onChange={(e) => setActiveModel(e.target.value)}
            label="Select GPT Model"
          >
            {gptModels.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveGptModel}
          sx={{ mt: 3, fontWeight: 'bold', backgroundColor: '#0066cc', '&:hover': { backgroundColor: '#004080' } }}
        >
          Save GPT Model
        </Button>
      </Box>

      <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', borderRadius: 1, border: '1px solid #ccc' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          Registration
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isRegistrationEnabled}
              onChange={handleRegistrationToggle}
              name="registrationEnabled"
              color="primary"
            />
          }
          label={isRegistrationEnabled ? "Registration Enabled" : "Registration Disabled"}
          sx={{ mt: 2 }}
        />
      </Box>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminPage;
