import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, useMediaQuery, useTheme } from '@mui/material';

const WelcomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ 
      display: 'flex', 
      minHeight: '100vh', // Nutzt die volle Viewport-Höhe
      backgroundColor: '#ffffff'
    }}>
      {/* Left Column - Welcome and Buttons */}
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isMobile ? '20px' : '60px',
        maxWidth: isMobile ? '100%' : '50%'
      }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2, fontWeight: 'bold', color: '#333', textAlign: 'center' }}>
          Welcome to the Audio Transcription Service
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, color: '#666', textAlign: 'center' }}>
          Please sign in or register to continue.
        </Typography>
        
        <Box sx={{ width: '100%', maxWidth: '300px', mb: 2 }}>
          <Button
            variant="contained"
            component={Link}
            to="/login"
            fullWidth
            sx={{
              backgroundColor: '#1976d2',
              color: '#ffffff',
              fontWeight: 'bold',
              padding: '12px 0',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#1565c0',
              },
            }}
          >
            SIGN IN
          </Button>
        </Box>
        
        <Box sx={{ width: '100%', maxWidth: '300px' }}>
          <Button
            variant="outlined"
            component={Link}
            to="/register"
            fullWidth
            sx={{
              borderColor: '#1976d2',
              color: '#1976d2',
              fontWeight: 'bold',
              padding: '12px 0',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#e3f2fd',
                borderColor: '#1565c0',
              },
            }}
          >
            REGISTER
          </Button>
        </Box>
      </Box>

      {/* Right Column - Image */}
      {!isMobile && (
        <Box sx={{ 
          flex: 1,
          backgroundImage: 'url("/audio-waves.png")', // Ersetzen Sie dies durch Ihr tatsächliches Bild
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: '#1976d2', // Fallback-Farbe, falls das Bild nicht lädt
        }} />
      )}
    </Box>
  );
};

export default WelcomePage;