import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
  Alert,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ResponseHistory from './ResponseHistory';

const TranscriptionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [transcription, setTranscription] = useState(null);
  const [gptModel, setGptModel] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');
  const [gptResponse, setGptResponse] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [responses, setResponses] = useState([]);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    console.log("Current user:", user);
    if (user) {
      fetchTranscriptionDetails();
      fetchSettings();
      fetchPrompts();
      fetchResponses();
    }
  }, [user, id]);

  const fetchTranscriptionDetails = async () => {
    const { data, error } = await supabase
      .from('transcriptions')
      .select('*')
      .eq('id', id)
      .eq('user_id', user.id)
      .single();

    if (error) {
      console.error('Error fetching transcription details:', error);
      setError('Failed to load transcription details. Please try again later.');
      navigate('/history');
    } else {
      setTranscription(data);
    }
  };

  const fetchSettings = async () => {
    const { data, error } = await supabase
      .from('settings')
      .select('key, value');

    if (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to load settings. Please try again later.');
    } else {
      const apiKeySetting = data.find(setting => setting.key === 'openai_api_key');
      const gptModelSetting = data.find(setting => setting.key === 'active_gpt_model');
      if (apiKeySetting) setApiKey(apiKeySetting.value);
      if (gptModelSetting) setGptModel(gptModelSetting.value);
    }
  };

  const fetchPrompts = async () => {
    const { data, error } = await supabase
      .from('prompts')
      .select('*')
      .eq('user_id', user.id);
  
    if (error) {
      console.error('Error fetching prompts:', error);
    } else {
      setPrompts(data);
    }
  };

  const fetchResponses = async () => {
    const { data, error } = await supabase
      .from('generated_responses')
      .select('*')
      .eq('transcription_id', id)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching responses:', error);
      setError('Failed to load responses. Please try again later.');
    } else {
      setResponses(data);
    }
  };

  const handleExecutePrompt = async () => {
    const promptToUse = selectedPrompt || customPrompt;
    if (!promptToUse) {
      setError('Please enter a prompt.');
      return;
    }

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          model: gptModel,
          messages: [{ role: 'user', content: promptToUse + "\n\n" + transcription.transcription_text }]
        })
      });

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error.message);
      }

      const responseText = data.choices[0].message.content;
      setGptResponse(responseText);

      // Save the response to the database
      const { error } = await supabase
        .from('generated_responses')
        .insert([{ transcription_id: id, response_text: responseText }]);

      if (error) {
        console.error('Error saving GPT response:', error);
        setError('Failed to save response. Please try again.');
      } else {
        fetchResponses();
      }
    } catch (error) {
      console.error('Error executing prompt:', error);
      setError('Failed to execute prompt. Please try again.');
    }
  };

  const handleDeleteResponse = async (responseId) => {
    const { error } = await supabase
      .from('generated_responses')
      .delete()
      .eq('id', responseId);

    if (error) {
      console.error('Error deleting response:', error);
      setError('Failed to delete response. Please try again.');
    } else {
      setResponses(responses.filter((response) => response.id !== responseId));
    }
  };

  const handleSaveEdit = async (responseId, newText) => {
    const { error } = await supabase
      .from('generated_responses')
      .update({ response_text: newText })
      .eq('id', responseId);

    if (error) {
      console.error('Error updating response:', error);
      setError('Failed to update response. Please try again.');
    } else {
      setResponses(responses.map(response =>
        response.id === responseId ? { ...response, response_text: newText } : response
      ));
    }
  };

  if (!user) {
    return <Typography sx={{ color: '#1a1a1a', padding: '16px' }}>Please log in to view transcription details.</Typography>;
  }

  if (!transcription) {
    return <Typography sx={{ color: '#1a1a1a', padding: '16px' }}>Loading transcription details...</Typography>;
  }

  return (
    <Box sx={{ width: '100%', padding: '16px', boxSizing: 'border-box' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#1a1a1a', fontWeight: 'bold', mb: 3 }}>
        Transcription Details
      </Typography>
      
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Box sx={{ marginBottom: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#f5f5f5' }}>
              <InputLabel>Select Pre-Configured Prompt</InputLabel>
              <Select
                value={selectedPrompt}
                onChange={(e) => setSelectedPrompt(e.target.value)}
                label="Select Pre-Configured Prompt"
              >
                <MenuItem value=""><em>-- Select a Prompt --</em></MenuItem>
                {prompts.map((prompt) => (
                  <MenuItem key={prompt.id} value={prompt.text}>
                    {prompt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Or Enter a Custom Prompt"
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
        </Grid>
        <Button
          onClick={handleExecutePrompt}
          variant="contained"
          color="primary"
          sx={{ 
            marginTop: 2, 
            fontWeight: 'bold', 
            backgroundColor: '#0066cc',
            '&:hover': {
              backgroundColor: '#004080',
            },
          }}
        >
          Execute Prompt
        </Button>
      </Box>

      <Box sx={{ marginBottom: 4, backgroundColor: '#f9f9f9', padding: 2, borderRadius: 1 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          Transcription Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Filename:</strong> {transcription.filename}</Typography>
            <Typography><strong>Date:</strong> {new Date(transcription.created_at).toLocaleString()}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Duration:</strong> {transcription.duration_seconds} seconds</Typography>
            <Typography><strong>Cost:</strong> ${transcription.cost_usd.toFixed(2)} USD</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          Transcription Text
        </Typography>
        <Box sx={{ 
          padding: 2, 
          backgroundColor: '#f5f5f5', 
          borderRadius: 1, 
          maxHeight: '200px', 
          overflowY: 'auto',
          border: '1px solid #e0e0e0'
        }}>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{transcription.transcription_text}</Typography>
        </Box>
      </Box>

      <ResponseHistory
        responses={responses}
        onEdit={handleSaveEdit}
        onDelete={handleDeleteResponse}
        onSaveEdit={handleSaveEdit}
      />
    </Box>
  );
};

export default TranscriptionDetail;