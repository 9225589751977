import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';

const AudioRecorder = ({ onRecordingComplete }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [timer, setTimer] = useState(0); // Timer to track duration
  const timerIntervalRef = useRef(null); // Ref to hold the timer interval
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const silenceTimer = useRef(null);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const dataArray = useRef(null);
  const streamRef = useRef(null); // Ref to hold the media stream

  const checkAudioSupport = () => {
    if (typeof MediaRecorder === 'undefined') {
      console.error('MediaRecorder is not supported in this browser');
      return false;
    }
    
    const mimeTypes = ['audio/webm', 'audio/mp4', 'audio/mpeg'];
    for (let type of mimeTypes) {
      if (MediaRecorder.isTypeSupported(type)) {
        console.log(`${type} is supported`);
        return type;
      }
    }
    
    console.error('No supported audio MIME types found');
    return null;
  };

  const getAudioConstraints = () => {
    const constraints = {
      audio: {
        echoCancellation: { ideal: true },
        noiseSuppression: { ideal: true },
        autoGainControl: { ideal: true },
        sampleRate: 44100,
        sampleSize: 16,
        channelCount: 1,
      }
    };

    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      constraints.audio.echoCancellation = false;
      constraints.audio.autoGainControl = false;
      constraints.audio.noiseSuppression = false;
    }

    return constraints;
  };

  const startRecording = async () => {
    const mimeType = checkAudioSupport();
    if (!mimeType) {
      alert('Audio recording is not supported in this browser');
      return;
    }
  
    audioChunks.current = [];
    try {
      console.log('Requesting media permissions...');
      const stream = await navigator.mediaDevices.getUserMedia(getAudioConstraints());
      streamRef.current = stream; // Store the media stream in the ref

      console.log('Permissions granted, creating MediaRecorder...');
      
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      analyser.current = audioContext.current.createAnalyser();
  
      const source = audioContext.current.createMediaStreamSource(stream);
      source.connect(analyser.current);
  
      analyser.current.fftSize = 2048;
      const bufferLength = analyser.current.fftSize;
      dataArray.current = new Uint8Array(bufferLength);
  
      mediaRecorder.current = new MediaRecorder(stream, { mimeType });

      // Start the timer
      setTimer(0);
      timerIntervalRef.current = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
  
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
  
      mediaRecorder.current.onstop = () => {
        clearInterval(timerIntervalRef.current); // Stop the timer
        console.log('MediaRecorder stopped, processing audio...');
        const audioBlob = new Blob(audioChunks.current, { type: mimeType });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);

        console.log('Duration:', timer, 'seconds');

        // Call the provided callback with the audio blob and duration
        onRecordingComplete(audioBlob, timer);

        // Stop the media stream tracks to release the microphone
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null; // Clear the stream ref
        }

        // Close the audio context
        if (audioContext.current && audioContext.current.state !== 'closed') {
          audioContext.current.close().catch(error => {
            console.error("Error closing audio context:", error);
          });
          audioContext.current = null;
        }
      };
  
      mediaRecorder.current.start(1000); // Capture in 1-second intervals
      setIsRecording(true);
      console.log('Recording started');
  
      monitorSilence();
    } catch (error) {
      console.error("Error accessing the microphone", error);
      alert("Error accessing the microphone. Please make sure you have given permission.");
    }
  };
  

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
      clearInterval(timerIntervalRef.current); // Stop the timer
      mediaRecorder.current.stop();
      setIsRecording(false);
      console.log('Recording stopped');
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
    }
  };

  const monitorSilence = () => {
    const checkForSilence = () => {
      analyser.current.getByteTimeDomainData(dataArray.current);
      let silenceDetected = true;

      for (let i = 0; i < dataArray.current.length; i++) {
        if (dataArray.current[i] > 128 + 10 || dataArray.current[i] < 128 - 10) {
          silenceDetected = false;
          break;
        }
      }

      if (silenceDetected) {
        if (!silenceTimer.current) {
          silenceTimer.current = setTimeout(stopRecording, 9000000000000); // Stop after 5 seconds of silence
        }
      } else {
        if (silenceTimer.current) {
          clearTimeout(silenceTimer.current);
          silenceTimer.current = null;
        }
      }

      if (isRecording) {
        requestAnimationFrame(checkForSilence);
      }
    };

    checkForSilence();
  };

  useEffect(() => {
    return () => {
      if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
        mediaRecorder.current.stop();
      }
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
      if (audioContext.current && audioContext.current.state !== 'closed') {
        audioContext.current.close().catch(error => {
          console.error("Error closing audio context:", error);
        });
        audioContext.current = null;
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }
      clearInterval(timerIntervalRef.current); // Ensure the timer is cleared on unmount
    };
  }, []);

  return (
    <div className="text-center mb-4">
      <button 
        onClick={isRecording ? stopRecording : startRecording}
        className={`
          w-24 h-24 rounded-full focus:outline-none focus:shadow-outline
          flex items-center justify-center
          ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'}
        `}
      >
        <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} size="4x" />
      </button>
      <p className="mt-2 text-sm text-gray-600">
        {isRecording ? `Recording... (${timer} seconds)` : 'Click to record'}
      </p>

      {audioURL && (
        <div className="mt-4">
          <audio controls src={audioURL}></audio>
        </div>
      )}
    </div>
  );
};

AudioRecorder.propTypes = {
  onRecordingComplete: PropTypes.func.isRequired,
};

export default AudioRecorder;
