import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient';
import AuthComponent from './components/Auth';
import AdminPage from './components/AdminPage';
import Register from './components/Register';
import TranscriptionPage from './components/TranscriptionPage';
import TranscriptionHistory from './components/TranscriptionHistory';
import TranscriptionDetail from './components/TranscriptionDetail';
import UserProfilePage from './components/UserProfilePage';
import WelcomePage from './components/WelcomePage';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, IconButton, CssBaseline, Button, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import UserIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { AppBar, Typography } from '@mui/material';
import { AuthProvider, AuthContext } from './AuthContext';

function AppContent() {
  const { user, setUser } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userGroup, setUserGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching session:', error);
      } else if (data && data.session) {
        setUser(data.session.user);
      }
      setLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setUser(session.user);
      } else if (event === 'SIGNED_OUT') {
        setUser(null);
      }
    });

    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, [setUser]);

  useEffect(() => {
    if (user) {
      fetchUserGroup(user.id);
    }
  }, [user]);

  const fetchUserGroup = async (userId) => {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('user_groups(name, can_access_admin)')
      .eq('id', userId)
      .single();
  
    if (error) {
      console.error('Error fetching user group:', error);
    } else if (data && data.user_groups) {
      setUserGroup(data.user_groups);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    } else {
      setUser(null);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const isWelcomePage = location.pathname === '/welcome';

  return (
    <>
      <CssBaseline />
      {!isWelcomePage && (
        <>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                Audio Live App
              </Typography>
              {user ? (
                <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
              ) : (
                <Button color="inherit" component={Link} to="/login">Sign In</Button>
              )}
            </Toolbar>
          </AppBar>

          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
            <List>
              <ListItem button component={Link} to="/" onClick={toggleDrawer}>
                <ListItemIcon>
                  <AudiotrackIcon />
                </ListItemIcon>
                <ListItemText primary="Transcription" />
              </ListItem>

              <ListItem button component={Link} to="/history" onClick={toggleDrawer}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="History" />
              </ListItem>

              <ListItem button component={Link} to="/profile" onClick={toggleDrawer}>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="User Profile" />
              </ListItem>

              {userGroup && userGroup.can_access_admin && (
                <ListItem button component={Link} to="/admin" onClick={toggleDrawer}>
                  <ListItemIcon>
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>
              )}
            </List>
          </Drawer>
        </>
      )}

      <main style={{ marginTop: isWelcomePage ? '0' : '64px', padding: isWelcomePage ? '0' : '20px' }}>
        <Routes>
          {/* Public Routes */}
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/login" element={user ? <Navigate to="/" /> : <AuthComponent />} />
          <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />

          {/* Protected Routes */}
          {user ? (
            <>
              <Route path="/" element={<TranscriptionPage />} />
              <Route path="/history" element={<TranscriptionHistory />} />
              <Route path="/transcription/:id" element={<TranscriptionDetail />} />
              <Route path="/profile" element={<UserProfilePage />} />
              {userGroup && userGroup.can_access_admin ? (
                <Route path="/admin" element={<AdminPage />} />
              ) : (
                <Route path="/admin" element={<Navigate to="/" replace />} />
              )}
            </>
          ) : (
            <Route path="*" element={<Navigate to="/welcome" />} />
          )}
        </Routes>
      </main>
    </>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;