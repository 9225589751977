export const transcribeAudio = async (audioBlob, apiKey, gptModel) => {
  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.wav'); // The key should be 'file'
  formData.append('model', 'whisper-1'); // Specify the model to use
  formData.append('model', gptModel || 'whisper-1'); // Verwende das angegebene Modell oder den Standardwert

  const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${apiKey}`, // Your API key
    },
    body: formData,
  });

  if (!response.ok) {
    const errorDetails = await response.json();
    console.error('Error details:', errorDetails); // Log the detailed error message
    throw new Error(`HTTP error! status: ${response.status}, message: ${errorDetails.error.message}`);
  }

  const data = await response.json();
  return data.text; // Extract the transcription text
};
