import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';  // Import PropTypes

const DropzoneWrapper = styled.div`
  border: 2px dashed #007bff;
  padding: 40px;
  text-align: center;
  color: #777;
  background-color: #f9f9f9;
  border-radius: 8px;
  transition: border 0.3s;

  &:hover {
    border-color: #333;
  }
`;

const DropzoneText = styled.p`
  margin-top: 10px;
  color: #555;
`;

const BrowseButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Dropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type.startsWith('audio')) {
        const audioBlob = new Blob([file], { type: file.type });
        onDrop(audioBlob);
      } else {
        alert("Please upload a valid audio file.");
      }
    },
    accept: '.wav, .mp3, .m4a, .webm, .mp4, .mpga, .mpeg', // Accepting wav and mp3 files
    maxSize: 200 * 1024 * 1024, // 200MB limit
    noClick: true,  // Disable the default click behavior to use custom button
  });

  return (
    <DropzoneWrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag and drop file here</p>
      <DropzoneText>Limit 25MB per file .wav, .mp3, .m4a, .webm, .mp4, .mpga, .mpeg</DropzoneText>
      <BrowseButton onClick={open}>Browse Files</BrowseButton>
    </DropzoneWrapper>
  );
};

// Add PropTypes validation for the onDrop prop
Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,  // Specify that onDrop is a required function prop
};

export default Dropzone;
