import React, { useEffect, useState, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  Box,
  useMediaQuery,
  useTheme,
  TableSortLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TranscriptionHistory = () => {
  const [transcriptions, setTranscriptions] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [filename, setFilename] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('created_at');
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (user) {
      fetchTranscriptions();
    }
  }, [user]);

  const fetchTranscriptions = async () => {
    const { data, error } = await supabase
      .from('transcriptions')
      .select('*')
      .eq('user_id', user.id);

    if (error) {
      console.error('Error fetching transcriptions:', error);
    } else {
      // Process the data to add a preview of the first 10 words from the transcription_text field
      const processedData = data.map((transcription) => ({
        ...transcription,
        preview: transcription.transcription_text
          ? transcription.transcription_text.split(' ').slice(0, 10).join(' ') + '...'
          : '',
      }));
      setTranscriptions(processedData);
    }
  };

  const handleDeleteTranscription = async (id) => {
    try {
      const { error } = await supabase
        .from('transcriptions')
        .delete()
        .eq('id', id)
        .eq('user_id', user.id);

      if (error) throw error;

      setTranscriptions(transcriptions.filter((transcription) => transcription.id !== id));
    } catch (error) {
      console.error('Error deleting transcription:', error.message);
    }
  };

  const handleEditFilename = (id, currentFilename) => {
    setEditingId(id);
    setFilename(currentFilename);
  };

  const handleSaveFilename = async (id) => {
    try {
      const { error } = await supabase
        .from('transcriptions')
        .update({ filename })
        .eq('id', id)
        .eq('user_id', user.id);

      if (error) throw error;

      setTranscriptions(transcriptions.map((transcription) =>
        transcription.id === id ? { ...transcription, filename } : transcription
      ));
      setEditingId(null);
    } catch (error) {
      console.error('Error updating filename:', error.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedData = [...transcriptions].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setTranscriptions(sortedData);
  };

  if (!user) {
    return <Alert severity="warning">Please log in to view your transcription history.</Alert>;
  }

  return (
    <Box sx={{ width: '100%', padding: '16px', boxSizing: 'border-box' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#1a1a1a', fontWeight: 'bold', mb: 3 }}>
        Transcription History
      </Typography>
      <TableContainer sx={{ 
        overflowX: 'auto', 
        '& .MuiTableCell-root': { 
          padding: isMobile ? '8px 4px' : '16px',
          whiteSpace: 'nowrap' 
        }
      }}>
        <Table sx={{ minWidth: isMobile ? '100%' : 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
              <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                <TableSortLabel
                  active={orderBy === 'filename'}
                  direction={orderBy === 'filename' ? order : 'asc'}
                  onClick={() => handleSortRequest('filename')}
                >
                  Filename
                </TableSortLabel>
              </TableCell>
              {!isMobile && (
                <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                  <TableSortLabel
                    active={orderBy === 'created_at'}
                    direction={orderBy === 'created_at' ? order : 'asc'}
                    onClick={() => handleSortRequest('created_at')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                <TableSortLabel
                  active={orderBy === 'preview'}
                  direction={orderBy === 'preview' ? order : 'asc'}
                  onClick={() => handleSortRequest('preview')}
                >
                  Preview
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: '#333' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transcriptions.map((transcription) => (
              <TableRow key={transcription.id} sx={{ '&:hover': { backgroundColor: '#f9f9f9' } }}>
                <TableCell>
                  {editingId === transcription.id ? (
                    <TextField
                      value={filename}
                      onChange={(e) => setFilename(e.target.value)}
                      onBlur={() => handleSaveFilename(transcription.id)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSaveFilename(transcription.id);
                        if (e.key === 'Escape') handleCancelEdit();
                      }}
                      autoFocus
                      variant="standard"
                      fullWidth
                    />
                  ) : (
                    <span 
                      onClick={() => handleEditFilename(transcription.id, transcription.filename)}
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#0066cc' }}
                    >
                      {transcription.filename}
                    </span>
                  )}
                </TableCell>
                {!isMobile && <TableCell>{new Date(transcription.created_at).toLocaleString()}</TableCell>}
                <TableCell>{transcription.preview}</TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    href={`/transcription/${transcription.id}`}
                    aria-label="view details"
                    sx={{ '&:hover': { color: '#004080' } }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteTranscription(transcription.id)}
                    aria-label="delete"
                    sx={{ '&:hover': { color: '#b30000' } }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TranscriptionHistory;
