import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { TextField, Button, Typography, Container, Alert } from '@mui/material';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistrationEnabled, setIsRegistrationEnabled] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    checkRegistrationStatus();
  }, []);

  const checkRegistrationStatus = async () => {
    const { data, error } = await supabase
      .from('settings')
      .select('value')
      .eq('key', 'registration_enabled')
      .single();

    if (error) {
      console.error('Error fetching registration status:', error);
      setError('Fehler beim Laden der Registrierungseinstellungen.');
    } else {
      setIsRegistrationEnabled(data.value === 'true');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!isRegistrationEnabled) {
      setError('Die Registrierung ist derzeit deaktiviert.');
      return;
    }
    try {
      const { user, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      if (error) throw error;
      alert('Registrierung erfolgreich! Bitte überprüfen Sie Ihre E-Mail zur Bestätigung.');
    } catch (error) {
      setError(error.message);
    }
  };

  if (!isRegistrationEnabled) {
    return (
      <Container maxWidth="xs">
        <Typography variant="h4" align="center" gutterBottom>
          Registrierung
        </Typography>
        <Alert severity="info">Die Registrierung ist derzeit deaktiviert.</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Registrierung
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleRegister}>
        <TextField
          label="E-Mail"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Passwort"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Registrieren
        </Button>
      </form>
    </Container>
  );
};

export default Register;