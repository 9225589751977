import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Wenn Sie möchten, dass Ihre App offline funktioniert und schneller lädt, können Sie
// unregister() zu register() ändern. Beachten Sie, dass dies einige Fallstricke hat.
// Erfahren Sie mehr über service workers: https://cra.link/PWA
serviceWorkerRegistration.register();