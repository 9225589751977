import React, { useState, useEffect, useContext, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';
import {
  Typography,
  TextField,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [newPromptName, setNewPromptName] = useState('');
  const [newPromptText, setNewPromptText] = useState('');
  const [editingPromptId, setEditingPromptId] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const navigate = useNavigate();

  const fetchPrompts = useCallback(async () => {
    console.log("Fetching prompts from database...");
    const { data, error } = await supabase
      .from('prompts')
      .select('*')
      .eq('user_id', user.id);
    if (error) {
      console.error('Error fetching prompts:', error);
    } else {
      console.log("Fetched prompts:", data);
      setPrompts(data);
    }
  }, [user.id]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        const { data, error } = await supabase.auth.getSession();
        if (error || !data.session) {
          navigate('/welcome');
          return;
        } else {
          setUser(data.session.user);
        }
      }

      setDisplayName(user?.user_metadata?.name || '');
      setEmail(user?.email);
      await fetchPrompts();
      setLoading(false);
    };

    fetchUserData();
  }, [user, navigate, setUser, fetchPrompts]);

  const showAlert = (message, severity = 'success') => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleUpdateProfile = async () => {
    if (password && password !== confirmPassword) {
      showAlert("Passwords do not match.", 'error');
      return;
    }

    const updates = {
      email: email,
      data: { name: displayName },
    };

    if (password) {
      updates.password = password;
    }

    const { data, error } = await supabase.auth.updateUser(updates);

    if (error) {
      console.error('Error updating profile:', error);
      showAlert('Error updating profile: ' + error.message, 'error');
    } else {
      setUser(data.user);
      showAlert('Profile updated successfully!', 'success');
    }
  };

  const handleAddOrUpdatePrompt = async () => {
    if (!newPromptName || !newPromptText) {
      showAlert("Please fill in both the prompt name and text before submitting.", 'warning');
      return;
    }

    if (editingPromptId) {
      const { error } = await supabase
        .from('prompts')
        .update({ name: newPromptName, text: newPromptText })
        .eq('id', editingPromptId);

      if (error) {
        console.error('Error updating prompt:', error);
        showAlert('Error updating prompt: ' + error.message, 'error');
      } else {
        setPrompts(prompts.map(prompt => 
          prompt.id === editingPromptId ? { ...prompt, name: newPromptName, text: newPromptText } : prompt
        ));
        setEditingPromptId(null);
        setNewPromptName('');
        setNewPromptText('');
        showAlert('Prompt updated successfully!', 'success');
      }
    } else {
      console.log("Adding new prompt...");
      const { data, error } = await supabase
        .from('prompts')
        .insert([{ name: newPromptName, text: newPromptText, user_id: user.id }]);

      if (error) {
        console.error('Error adding prompt:', error);
        showAlert('Error adding prompt: ' + error.message, 'error');
      } else {
        setNewPromptName('');
        setNewPromptText('');
        await fetchPrompts();
        showAlert('Prompt added successfully!', 'success');
      }
    }
  };

  const handleEditPrompt = (prompt) => {
    setNewPromptName(prompt.name);
    setNewPromptText(prompt.text);
    setEditingPromptId(prompt.id);
  };

  const handleDeletePrompt = async (id) => {
    const { error } = await supabase
      .from('prompts')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting prompt:', error);
      showAlert('Error deleting prompt: ' + error.message, 'error');
    } else {
      setPrompts(prompts.filter(prompt => prompt.id !== id));
      showAlert('Prompt deleted successfully!', 'success');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto', padding: '16px' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
        User Profile
      </Typography>
      
      <Box component="form" onSubmit={handleUpdateProfile} sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Display Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateProfile}
              fullWidth
              sx={{ mt: 2, fontWeight: 'bold' }}
            >
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#333', fontWeight: 'bold', mt: 4 }}>
        Prompt Management
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Prompt Name"
              value={newPromptName}
              onChange={(e) => setNewPromptName(e.target.value)}
              variant="outlined"
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Prompt Text"
              value={newPromptText}
              onChange={(e) => setNewPromptText(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
              sx={{ backgroundColor: '#f5f5f5' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddOrUpdatePrompt}
              fullWidth
              sx={{ mt: 2, fontWeight: 'bold' }}
            >
              {editingPromptId ? 'Update Prompt' : 'Add Prompt'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <List sx={{ bgcolor: '#f5f5f5', borderRadius: '4px' }}>
        {prompts.map((prompt) => (
          <ListItem
            key={prompt.id}
            secondaryAction={
              <Box>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditPrompt(prompt)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePrompt(prompt.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
          >
            <ListItemText
              primary={prompt.name}
              secondary={prompt.text}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
        ))}
      </List>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserProfile;
