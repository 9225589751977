import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, CircularProgress, Alert, useMediaQuery, useTheme } from '@mui/material';
import AudioRecorder from './AudioRecorder';
import Dropzone from './Dropzone';
import { transcribeAudio } from '../services/transcriptionService';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../AuthContext';

const TranscriptionPage = () => {
  const [transcription, setTranscription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [gptModel, setGptModel] = useState('');
  const { user } = useContext(AuthContext);
  const [userGroup, setUserGroup] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(null); // Add this line
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (user) {
      fetchUserGroupAndSettings();
    }
  }, [user]);

  const fetchUserGroupAndSettings = async () => {
    try {
      // Fetch user profile and group
      const { data: profileData, error: profileError } = await supabase
        .from('user_profiles')
        .select('user_groups(id, name, can_access_admin)')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;

      if (profileData && profileData.user_groups) {
        setUserGroup(profileData.user_groups);
      }

      // Fetch global settings
      const { data: settingsData, error: settingsError } = await supabase
        .from('settings')
        .select('key, value');

      if (settingsError) throw settingsError;

      if (settingsData) {
        const apiKeySetting = settingsData.find(setting => setting.key === 'openai_api_key');
        const gptModelSetting = settingsData.find(setting => setting.key === 'active_gpt_model');
        if (apiKeySetting) setApiKey(apiKeySetting.value);
        if (gptModelSetting) setGptModel(gptModelSetting.value);
      }
    } catch (error) {
      console.error('Error fetching user group or settings:', error);
      setError('Failed to load settings. Please try again later.');
    }
  };

  const handleSaveTranscription = async (transcriptionData) => {
    const { filename, transcriptionText, durationSeconds, costUsd, details } = transcriptionData;

    const { data, error } = await supabase
      .from('transcriptions')
      .insert([
        { 
          filename, 
          transcription_text: transcriptionText, 
          duration_seconds: durationSeconds, 
          cost_usd: costUsd, 
          details,
          user_id: user.id
        }
      ]);

    if (error) {
      console.error('Error saving transcription:', error);
      throw error;
    } else {
      console.log('Transcription saved successfully:', data);
      return data;
    }
  };

  const calculateCost = (durationInSeconds) => {
    const costPerSecond = 0.05; // Cost is $0.05 per second
    const cost = durationInSeconds * costPerSecond;
  
    // If cost is NaN or less than 0 (for safety), return 99
    return isNaN(cost) || cost < 0 ? 99 : cost;
  };
   


  

  const handleAudioData = async (audioBlob, durationInSeconds) => {
    if (!apiKey) {
      setError('API key not set. Please contact an administrator.');
      return;
    }
  
    setIsLoading(true);
    setError('');
    setRecordingDuration(durationInSeconds); // Set the recording duration in the UI
  
    try {
      // Transcribe the audio using the transcription API
      const transcriptionText = await transcribeAudio(audioBlob, apiKey, gptModel);
      setTranscription(transcriptionText);
  
      // Calculate the cost based on the recording duration
      const costUsd = calculateCost(durationInSeconds); 
  
      // Save the transcription data to the database
      await handleSaveTranscription({
        filename: audioBlob.name || 'Recorded Audio',
        transcriptionText,
        durationSeconds: durationInSeconds || 0, // Use the duration of the recording
        costUsd: costUsd.toFixed(2), // Format cost to two decimal places
        details: { userGroup: userGroup?.name, gptModel: gptModel },
      });
    } catch (error) {
      console.error('Transcription failed:', error.message);
      setError('Unable to process audio. Please try again or contact an administrator.');
    }
  
    setIsLoading(false);
  };
  

  if (!user) {
    return <Typography sx={{ color: '#1a1a1a', padding: '16px' }}>Please log in to access this page.</Typography>;
  }

  return (
    <Box sx={{ width: '100%', padding: '16px', boxSizing: 'border-box' }}>
      <Typography variant="h4" component="h1" sx={{ color: '#1a1a1a', fontWeight: 'bold', mb: 3 }}>
        Audio Transcription Service
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Box sx={{ 
        backgroundColor: '#f5f5f5', 
        padding: isMobile ? '16px' : '24px', 
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        mb: 4 
      }}>
        <AudioRecorder onRecordingComplete={handleAudioData} />
      </Box>
      <Typography variant="h5" component="h2" sx={{ color: '#333', fontWeight: 'bold', mt: 4, mb: 2 }}>
        Upload any audio file and get the transcript
      </Typography>
      <Box sx={{ mt: 2, mb: 4 }}>
        <Dropzone onDrop={handleAudioData} />
      </Box>
      {isLoading && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {transcription && (
        <Box sx={{ 
          mt: 4, 
          backgroundColor: '#f9f9f9', 
          p: isMobile ? 2 : 3, 
          borderRadius: '8px',
          border: '1px solid #e0e0e0'
        }}>
          <Typography variant="h6" component="h3" sx={{ color: '#333', fontWeight: 'bold', mb: 2 }}>
            Transcription:
          </Typography>
          <Box sx={{ 
            backgroundColor: '#ffffff', 
            p: 2, 
            borderRadius: '4px', 
            border: '1px solid #e0e0e0',
            maxHeight: '300px',
            overflowY: 'auto'
          }}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
              {transcription}
            </Typography>
            {recordingDuration && (
              <Typography variant="body2" sx={{ mt: 2, color: '#666' }}>
               Duration: {recordingDuration} seconds
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TranscriptionPage;